import React, { Fragment } from 'react';
import { TRACK_HIDDEN_FIELDS as FIELDS } from 'constants/forms';

export default function HiddenTrackFields() {
  return (
    <Fragment>
      {FIELDS.map(f => <input type="hidden" name={f} value="" />)}
    </Fragment>
  );
}
